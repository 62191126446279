import React from 'react'

interface BotShapeProps {
  h?: number
  s?: number
  l?: number
  background?: boolean
}

const BotShape = ({
  h = 216,
  s = 81,
  l = 51,
  background = false
}: BotShapeProps) => (
  <g id='robotShape'>
    {background && (
      <rect fill={`hsl(${h}, ${s}%, ${l}%)`} width='100%' height='100%' />
    )}
    <path
      fill='#CECECE'
      d='M36.4,89.9v121c0,4.5,1,8.9,3.1,12.9c3.4,6.6,10.2,15.3,23.1,18.8c21.9,5.9,48.7,13,48.7,13s9.3,1.5,21.3,0
      c0,0,94.9-10.5,109.7-22.5c0,0,17.3-13.3,17.1-26.4c-0.2-13.1,0-100.9,0-100.9s0.9-37.5-26.5-40.6c-30.9-4.4-100.2-15-100.2-15
      s-12.8-0.8-28.5,3c-12,2.9-37.6,8.9-48.8,11.5c-4.6,1.1-8.8,3.6-11.9,7.2C40,76.1,36.4,82.2,36.4,89.9z'
    />
    <path fill='#CF339B' d='M263.6,220.4' />
    <path
      fill={`hsl(${h}, ${s}%, ${l}%)`}
      d='M104.1,217.9V88.2c0-1,0.1-2.1,0.4-3.1c1.5-5.7,7.6-23.6,25.2-23.6c20.9,0,103.4,12.2,103.4,12.2
      s21.2,5,21.9,28.4c0.6,20.9,0.1,82.1,0,95.2c0,2.3-0.3,4.6-1,6.7c-1.8,5.9-6.5,16.4-18.7,21.7c-17.5,7.5-98,16.9-98,16.9
      S102.6,244.5,104.1,217.9z'
    />
    <ellipse fill='#ADADAD' cx='72.3' cy='149.3' rx='27.1' ry='35.8' />
    <ellipse
      fill={`hsl(${h}, ${s}%, ${l}%)`}
      cx='62.6'
      cy='149.3'
      rx='22.8'
      ry='30.1'
    />
    <path
      fill='none'
      stroke='#000000'
      strokeWidth='6'
      strokeLinecap='round'
      strokeMiterlimit='10'
      d='M61,128v-18.3
      c0-2.7-1.6-5.3-4.2-6.3c-0.1,0-0.1-0.1-0.2-0.1c-3.9-1.6-8.1-3.1-8.1-3.1s-3.3-2.2,3.9-5.6c7.2-3.4,13.4-6.7,13.4-6.7
      s4.2-1.7-3.3-4.7c-1.6-0.6-3-1.2-4-1.6c-2.5-1.1-4.1-3.5-4.2-6.2l-0.3-17.6'
    />
    <path
      d='M53,64.3c0,0,0,0,5.1-0.3c5.1-0.3,6.7-5.3,5.9-12.5c-0.9-7.2-5.4-9.9-10.6-9.4s-7.4,1.7-7.7,12.1
      c-0.3,10.4,7.9,10.1,7.9,10.1'
    />
    <path fill='none' stroke='#1D1D1B' strokeMiterlimit='10' d='M166,188.8' />
    <path
      fill='#1D1D1B'
      d='M108.9,215.6L109,92c0-1,0.1-1.9,0.4-2.8c1.4-5.3,6.9-21.9,22.8-21.9c18.9,0,93.5,11.3,93.5,11.3
      s19.2,4.6,19.8,26.3c0.5,19.3,0.1,76.1,0,88.3c0,2.1-0.3,4.2-0.9,6.2c-1.6,5.5-5.9,15.2-16.9,20.1c-15.8,6.9-88.7,15.6-88.7,15.6
      S107.5,240.2,108.9,215.6z'
    />
  </g>
)

export default BotShape
